import {
    apiList,
    Api
} from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";
import JsBarcode from "jsbarcode"; //条形码
import inputnumber from '@/components/inputNumber.vue'
export default {
    inject: ["reload"],
    components: {
        draggable,
        sorttable,
        paging,
        inputnumber
    },
    data() {
        return {
            activeName: 'first', //      tab标识
            logoUrl: '',
            judge: false, //      输入框绑定动态class
            isActive: true, //      输入框绑定动态class
            MoreConditions: '更多条件',
            queryData: {
                billStorePackNumber: '',
                memberId: '',
                nickname: '',
                packStatusMore: ['yi_wan_cheng:pack_status'],
                transportId: '',
                packStartTime: '',
                packEndTime: '',
            }, //       打包批次查询框
            queryData1: {
                memberId: '',
                nickname: '',
                storageId: '',
                checkStatus: '',
                isRejection: '',
                intoStockStartTime: '',
                intoStockEndTime: '',
            }, //       打包批次查询框
            cangkuList: [], //       仓库数组
            packStatusList: [], //       打包状态数组
            TransportMethodList: [], //       运输方式数组
            inspectionArr: [], //       验货申请数组
            refuseArr: [], //       验货申请数组
            tableData: [], //       打包批次表格
            tableData1: [], //       打包批次表格
            originalTabelHeadeTitle: [],
            originalTabelHeadeTitle1: [],
            tabelHeadeTitle: [{
                name: "打包批次号",
                field: "number",
                width: "180",
                sort: 16,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "出库单号",
                field: "billStorePackNumber",
                width: "180",
                sort: 16,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "仓库",
                field: "storageName",
                width: "120",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包状态",
                field: "packStatusShow",
                width: "120",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员名称",
                field: "nickname",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "120",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "团号",
                field: "invitationCode",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "收件人姓名",
                field: "consigneeName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "收件人电话",
                field: "receivingPhone",
                width: "180",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "收件国家/地区",
                field: "chineseName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "收货邮政编号",
                field: "postCode",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "收货城市",
                field: "cityName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "收货详细地址",
                field: "addressee",
                width: "160",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "业务单据类型",
                field: "billPackTypeShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "运输方式",
                field: "transportName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "渠道名称",
                field: "channelName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "渠道代理",
                field: "agentName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "运单号",
                field: "channelNumber",
                width: "180",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹总重量(KG)",
                field: "sumWeight",
                width: "160",
                sort: 13,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "包裹总体积(m³)",
                field: "volume",
                width: "160",
                sort: 14,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "包裹总件数",
                field: "sumCount",
                width: "120",
                sort: 12,
                isShow: true,
                isTotal: true,
                isSort: true,
            },

            {
                name: "打包备注",
                field: "comment",
                width: "160",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包金额",
                field: "totalReceivable",
                width: "120",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包申请时间",
                field: "createTime",
                width: "160",
                sort: 22,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "申请人",
                field: "applicantUserName",
                width: "120",
                sort: 22,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "拣货人",
                field: "packingDownUserName",
                width: "120",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "拣货时间",
                field: "packingDownTime",
                width: "160",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包完成时间",
                field: "packTime",
                width: "160",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包人",
                field: "packerUserName",
                width: "120",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            ], //       打包批次默认表头
            tableHeigth: "50vh", //       表格高度
            myTableHeard: [], //       动态表头
            pageNum: 1, //        页码
            total: 0,
            sizeList: [10, 20, 50, 100, 200],
            size: 50, //         一页几条
            singleData: [], //         编辑用的单条数据
            // ======公用组件==========
            show_sortTableHeard: false, //显示弹窗
            show_sortTableHeard1: false, //显示弹窗
            show_upload: false, //显示上传弹窗
            myTableHeard1: [], //我的表头
            selectLength: 0, //显示的长度
            selectLength1: 0, //显示的长度
            allCheck: false, //全选
            allCheck1: false, //全选
            tabelHeadeTitle1: [{
                name: "快递单号",
                field: "courierNumber",
                width: "120",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "快递公司",
                field: "expressName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包批次号",
                field: "billPackNumber",
                width: "120",
                sort: 16,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "仓库",
                field: "storageName",
                width: "120",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员名称",
                field: "nickname",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "120",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "团号",
                field: "invitationCode",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "转单号",
                field: "",
                width: "120",
                sort: 17,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "品名",
                field: "packageItem",
                width: "120",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "件数",
                field: "boxCount",
                width: "120",
                sort: 12,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "实际重量(KG)",
                field: "packageWeight",
                width: "120",
                sort: 13,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "实际体积(m³)",
                field: "volume",
                width: "120",
                sort: 14,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "长宽高(cm)",
                field: "volumeDetail",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹备注",
                field: "comment",
                width: "120",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "打包确认时间",
                field: "packTime",
                width: "180",
                sort: 22,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "操作人",
                field: "packerUserName",
                width: "120",
                sort: 22,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            ],
            tableData1: [],
            number3: "",
            number2: "",
            number1: "",
            totalArr: [], //需要合计的字段
            totalArr1: [], //需要合计的字段
            className: "", //导出需要
            className1: "", //导出需要
            fieldName: [], //下载字段名
            yanhuoList: [], //验货状态
            head: [], //下载表头字段
            fileList: [],
            uploadUrl: "", //上传路径
            sortName: '模板名', //排序名
            sortName1: '模板名', //排序名
            pageGroupName: 'WarehousePackagin', //页面标识
            // ======通用==========
            ids: [], //选择的数组
            show_PrintCate: false,


            // 输入快递单号弹窗
            showInputBox: false, // 
            showInputBox2: false, // 
            showInputBox3: false, // 
            newArrs: [],
            time_first: [],
            time_2: [],

        };
    },
    updated() {
        if (this.show_sortTableHeard) {
            // 排序表头
            let arr = this.tabelHeadeTitle;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle = arr;
        }
        if (this.show_sortTableHeard1) {
            // 排序表头
            let arr = this.tabelHeadeTitle1;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle1 = arr;
        }
    },
    created() {
        // this.fetTableHeight();
        if (this.originalTabelHeadeTitle.length == 0) {
            this.originalTabelHeadeTitle = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle)
            );
        }
        if (this.originalTabelHeadeTitle1.length == 0) {
            this.originalTabelHeadeTitle1 = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle1)
            );
        }
        this.getGroupFindFieldSort(); //获取用户自定义表头

        let endtime = tools.getCurrentDate(); //今天的时间;
        let sarttime = tools.getFlexDate(-10); //10天前
        this.time_first = [sarttime, endtime];
        this.time_2 = [sarttime, endtime];
        this.queryData.packStartTime = sarttime;
        this.queryData.packEndTime = endtime;
        // this.queryData.packStartTime = sarttime + " 00:00:00";
        // this.queryData.packEndTime = endtime + " 23:59:59";
        this.queryData1.intoStockStartTime = sarttime;
        this.queryData1.intoStockEndTime = endtime;

        // 获取logo
        Api.logoInfo().then((res) => {
            if (res.data.status == "success") {
                let logoUrl = res.data.result.url || '';
                this.logoUrl = logoUrl
            }
        });
    },
    mounted() {
        this.getStatusValList("bill_pack_v1.pack_status")
        this.getStatusValList("bill_inspection_v1.check_status")
        this.getData();
        this.getStorageList(); //  仓库列表
        this.getTransportMethodList(); //  获取运输方式列表
        // window.onresize = () => {
        //     return (() => {
        //         this.fetTableHeight();
        //     })();
        // };
    },
    watch: {},
    methods: {
        rowclick(row, event, column) {
            this.$refs.mytable.toggleRowSelection(row);
        },
        rowclick2(row, event, column) {
            this.$refs.mytable2.toggleRowSelection(row);
        },
        //时间
        paymentTime1(e) {
            // if (e && e.length > 0) {
            //     this.queryData.packStartTime = e[0] + " " + "00:00:00";
            //     this.queryData.packEndTime = e[1] + " " + "23:59:59";
            // } else {
            //     this.queryData.packStartTime = "";
            //     this.queryData.packEndTime = "";
            // }
            // this.queryBtn_ok();
        },
        //时间
        paymentTime(e) {
            if (e && e.length > 0) {
                this.queryData1.intoStockStartTime = e[0] + " " + "00:00:00";
                this.queryData1.intoStockEndTime = e[1] + " " + "23:59:59";
            } else {
                this.queryData1.intoStockStartTime = "";
                this.queryData1.intoStockEndTime = "";
            }
            this.queryBtn_ok1();
        },


        //点击输入快递单号 (待认领)
        clickInput() {
            this.showInputBox = true
        },
        showInputBoxBtn(val) {
            this.showInputBox = false
            this.number1 = val;
            this.getData();
        },
        closeDiasInputVal(val) {
            this.showInputBox = val || false
        },
        //点击输入快递单号(打包明细)
        clickInput2() {
            this.showInputBox2 = true
        },
        showInputBoxBtn2(val) {
            this.showInputBox2 = false
            this.number2 = val;
            this.storagePackDetailList();
        },
        closeDiasInputVal2(val) {
            this.showInputBox2 = false;
        },
        //点击输入批次号(打包明细)
        clickInput3() {
            this.showInputBox3 = true
        },
        showInputBoxBtn3(val) {
            this.showInputBox3 = false
            this.number3 = val;
            this.storagePackDetailList();
        },
        closeDiasInputVal3(val) {
            this.showInputBox3 = false;
        },


        //打印
        billPrintClick() {
            let that = this;

            setTimeout(() => {
                that.$print(this.$refs.print);
            }, 10);
        },
        // 打印按钮
        printHuoJia() {
            let that = this;
            if (this.singleData.length < 1) {
                this.$message.warning("至少选择一项");
                return;
            }
            let newArrs = [];
            for (var a = 0; a < this.singleData.length; a++) {
                if (!this.singleData[a].billStorePackNumber) {
                    this.$message.warning("所选项中有未完成打包的项");
                    return
                }
                let items = {
                    datas: []
                }
                for (var b = 0; b < this.singleData[a].sumCount; b++) {
                    let objs = this.singleData[a]
                    items.datas.push(objs)
                }

                newArrs.push(items)
            }

            this.newArrs = newArrs
            this.show_PrintCate = true;
            this.$nextTick(() => {
                for (var i = 0; i < newArrs.length; i++) {
                    for (var c = 0; c < newArrs[i].datas.length; c++) {
                        //生成条形码
                        that.createCode(
                            "#printHj_" + i + '_' + c,
                            newArrs[i].datas[c].billStorePackNumber
                        );
                    }
                }
            });
        },
        // 创建条形码
        createCode(id, data) {
            JsBarcode(id, data, {
                format: "code128", //选择要使用的条形码类型
                width: 2, //设置条之间的宽度
                height: 100, //高度
                displayValue: false, //是否在条形码下方显示文字
                // text: "456", //覆盖显示的文本
                fontOptions: "bold", //使文字加粗体或变斜体
                // font: "fantasy", //设置文本的字体
                // textAlign: "left", //设置文本的水平对齐方式
                // textPosition: "top", //设置文本的垂直位置
                textMargin: 20, //设置条形码和文本之间的间距
                fontSize: 24, //设置文本的大小
                background: "#ffffff", //设置条形码的背景
                lineColor: "#000", //设置条和文本的颜色。
                margin: 6, //设置条形码周围的空白边距
            });
        },
        // 关闭打印弹窗
        closePrint() {

        },

        //重置
        Reset() {
            this.queryData = {
                billStorePackNumber: '',
                memberId: '',
                nickname: '',
                packStatusMore: ['yi_wan_cheng:pack_status'],
                transportId: '',
                packStartTime: '',
                packEndTime: '',
            }
            this.number1 = ""
            this.time_2 = []
        },
        Reset1() {
            this.queryData1 = {
                memberId: '',
                nickname: '',
                storageId: '',
                checkStatus: '',
                isRejection: '',
                intoStockStartTime: '',
                intoStockEndTime: '',
            }
            this.number2 = "";
            this.number3 = "";
            this.time_first = [];
        },
        getData() { //  获取数据
            let that = this;
            let nidlist = new Array
            nidlist = tools.getOrderNum(this.number1)
            let packEndTime = "";
            let packStartTime = "";
            if (that.queryData.packEndTime) {
                packEndTime = that.queryData.packEndTime + " " + "23:59:59";
            }
            if (that.queryData.packStartTime) {
                packStartTime = that.queryData.packStartTime + " " + "00:00:00";
            }

            this.queryData.packStartTime = packStartTime;
            this.queryData.packEndTime = packEndTime;
            this.queryData.number = nidlist
            this.queryData.pageStart = this.pageNum
            this.queryData.pageTotal = this.size
            Api.getStockPackList(this.queryData).then((res) => {
                if (res.data.status === "success") {
                    this.className = res.data.result.className || "";
                    this.tableData = res.data.result.data || [];
                    this.total = res.data.result.pageCount || 0;
                    this.fetTableHeight();
                } else {
                    this.$message.error(`${res.data.message}--打包批次列表`)
                }
            });

            if (that.queryData.packEndTime) {
                that.queryData.packEndTime = that.queryData.packEndTime.slice(0, 10)
            }
            if (that.queryData.packStartTime) {
                that.queryData.packStartTime = that.queryData.packStartTime.slice(0, 10)
            }
        },
        //
        storagePackDetailList() {
            let that = this;
            let nidlist = [];
            let bplist = [];
            nidlist = tools.getOrderNum(this.number2)
            bplist = tools.getOrderNum(this.number3)
            this.queryData1.courierNumber = nidlist
            this.queryData1.billPackNumber = bplist
            this.queryData1.pageStart = this.pageNum
            this.queryData1.pageTotal = this.size

            let intoStockEndTime = "";
            let intoStockStartTime = "";
            if (that.queryData1.intoStockEndTime) {
                intoStockEndTime = that.queryData1.intoStockEndTime + " " + "23:59:59";
            }
            if (that.queryData1.intoStockStartTime) {
                intoStockStartTime = that.queryData1.intoStockStartTime + " " + "00:00:00";
            }

            this.queryData1.intoStockStartTime = intoStockStartTime
            this.queryData1.intoStockEndTime = intoStockEndTime


            Api.storagePackDetailList(this.queryData1).then((res) => {
                if (res.data.status === "success") {
                    this.className1 = res.data.result.className || "";
                    this.tableData1 = res.data.result.data || [];
                    this.total = res.data.result.pageCount || 0;
                    this.fetTableHeight();
                } else {
                    this.$message.error(`${res.data.message}--打包批次列表`)
                }
            });

            if (that.queryData1.intoStockEndTime) {
                that.queryData1.intoStockEndTime = that.queryData1.intoStockEndTime.slice(0, 10)
            }
            if (that.queryData1.intoStockStartTime) {
                that.queryData1.intoStockStartTime = that.queryData1.intoStockStartTime.slice(0, 10)
            }
        },

        queryBtn_ok() { //  查询按钮
            this.pageNum = 1;
            this.getData();
        },
        queryBtn_ok1() { //  查询按钮
            this.pageNum = 1;
            this.storagePackDetailList();
        },


        getStorageList() { //  获取仓库下拉列表
            Api.getStorageList().then((res) => {
                this.cangkuList = res.data.result
            });
        },


        getTransportMethodList() { //  获取运输方式列表
            Api.getTransportMethodList().then((res) => {
                this.TransportMethodList = res.data.result.data || [];
            });
        },

        more() { //  输入框绑定动态class
            this.isActive = !this.isActive
            this.judge = !this.judge
            this.fetTableHeight();
            if (this.judge) {
                this.MoreConditions = '收起条件'
            } else {
                this.MoreConditions = '更多条件'
            }
        },

        handleSelectionChange(e) { //  表格选择事件
            this.singleData = e
        },
        //取消打包
        cancelbut() {
            let singleData = this.singleData
            let _this = this
            if (singleData.length == 1) {
                this.$confirm('是否确定取消打包?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let param = new Object
                    param.billPackId = singleData[0].billPackId
                    let sign = tools.getSign(param)
                    param.sign = sign
                    Api.cancelPack(param).then((res) => {
                        if (res.data.status == "success") {
                            this.$message.success('取消申请成功')
                            _this.getData()
                        }
                    })
                })

            } else {
                this.$message.warning('请勾选一条数据')
            }
        },
        PackingConfirmation() { // 打包确认
            if (this.singleData.length === 1) {
                this.$router.push({
                    path: '/BusinessManagement/ExWarehouse/PackingConfirmation',
                    query: {
                        id: this.singleData[0].billPackId
                    }
                })
            } else {
                this.$message.warning('请选择一条数据');
            }
        },
        PackingCancel() { // 取消确认
            let that = this;
            // console.log(this.singleData)
            if (this.singleData.length === 1) {
                if (this.singleData[0].packStatus != "yi_wan_cheng:pack_status") {
                    this.$message.warning('该包裹还没打包确认,无须取消确认');
                    return
                }
                this.$confirm('是否取消确认当前包裹?', '提示').then(() => {
                    let param = {
                        billStorepackId: this.singleData[0].billstorepackId, //仓库打包单id
                        billStorepackNumber: this.singleData[0].billStorePackNumber, //仓库打包单编号
                    }
                    let sign = tools.getSign(param);
                    param.sign = sign
                    Api.cancelPackStorage(param).then(res => {
                        if (res.data.status == 'success') {
                            this.$message.success(res.data.message || '')
                            that.getData();
                        }
                    })
                }).catch(() => {

                })
            } else if (this.singleData.length > 1) {
                this.$message.warning('一次只能修改一条数据');
            } else if (this.singleData.length < 1) {
                this.$message.warning('请选择一条数据');
            }
        },
        EditConfirmation() { // 修改打包单
            console.log(this.singleData[0].packStatusShow)

            if (this.singleData.length === 1) {
                if (this.singleData[0].packStatusShow === '已完成打包' || this.singleData[0].packStatusShow === '已出库待签收') {
                    this.$router.push({
                        path: '/BusinessManagement/ExWarehouse/EditPackingConfirmation',
                        query: {
                            id: this.singleData[0].billPackId
                        }
                    })
                } else {
                    this.$message.warning('请选择状态为已完成打包或已出库待签收');
                }

            } else {
                this.$message.warning('请选择一条数据');
            }
        },
        // 获取状态展示值
        getStatusValList(status) {
            var dataArr = [];
            // 包裹状态: ==========> package_v1.package_status
            // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
            // 验货单验货状态: ====> bill_inspection_v1.check_status
            // 审批状态:==========> finance_in_record_v1.approve_status
            // 核销状态:==========> finance_in_record_v1.writeoff_status
            // 单据类型:==========> finance_in_record_v1.document_status
            // 打包状态:==========> bill_pack_v1.pack_status
            let param = {
                tableAndFieldName: status,
            };
            Api.getStatusValList(param).then((res) => {
                // 验货状态
                if (status == "bill_inspection_v1.check_status") {
                    let dataArr = res.data.result || [];
                    this.yanhuoList = dataArr

                    console.log(this.yanhuoList)
                    console.log('验货状态')
                }

            });
            Api.getStatusValList(param).then((res) => {
                // 打包状态
                if (status == "bill_pack_v1.pack_status") {
                    let dataArr = res.data.result || [];
                    this.packStatusList = dataArr
                }

            });

        },


        //导出操作
        handleCommand_dc(command) {
            if (command == 'dc') {
                // 导出
                this.exportBtn()
            } else if (command == 'dcmb') {
                // 下载模板
                this.exportTemplateDownload()
            } else if (command == 'scmb') {
                // 上传模板
                this.upLoadBtn()
            }
        },
        //修改
        modify() {
            this.$router.push('/BusinessManagement/Warehousing/ReceiptConfirmation?pageType=modify')
        },


        // ===============================================导出部分=开始======
        // 上传导出模板
        uploadSectionFile(param) {
            console.log(param);
            const file = param.file,
                fileName = file.name;

            // 根据后台需求数据格式
            const form = new FormData();
            // 文件对象
            form.append("file", file);
            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            form.append("fileName", fileName);
            let signArr = [{
                key: "fileName",
                val: fileName,
            },];
            let sign = tools.getMD5Sign(signArr);
            form.append("sign", sign);
            // 项目封装的请求方法，下面做简单介绍
            Api.uploadFile(form)
                .then((res) => {
                    if (res.data.status == "success") {
                        let fileName = res.data.result.fileName;

                        let signArr = [{
                            key: "groupName",
                            val: this.pageGroupName,
                        },
                        {
                            key: "templateName",
                            val: this.sortName,
                        },
                        {
                            key: "fileName",
                            val: fileName,
                        },
                        ];
                        let sign_ = tools.getMD5Sign(signArr);
                        Api.saveExportTemplate({
                            groupName: this.pageGroupName,
                            templateName: this.sortName,
                            fileName: fileName,
                            sign: sign_,
                        }).then((res) => {
                            this.$message.success(res.data.message || "更新成功");
                            this.show_upload = false;
                        });
                    }
                })
                .catch((err) => { });
        },

        // 下载用户导出模板
        exportTemplateDownload() {
            var fieldName = this.fieldName;
            var head = this.head;
            if (head.length == 0) {
                let fieldNames = [];
                let heads = [];
                if (this.pageGroupName == 'WarehousePackagin') {
                    for (var i = 0; i < this.myTableHeard.length; i++) {
                        if (this.myTableHeard[i].isShow) {
                            fieldName.push(this.myTableHeard[i].field);
                            head.push(this.myTableHeard[i].name);
                        }
                    }
                }
                if (this.pageGroupName == 'WarehousePackagins') {
                    for (var i = 0; i < this.myTableHeard1.length; i++) {
                        if (this.myTableHeard1[i].isShow) {
                            fieldName.push(this.myTableHeard1[i].field);
                            head.push(this.myTableHeard1[i].name);
                        }
                    }
                }
                fieldName = fieldNames;
                head = heads;
            }
            //加密数组格式
            let signArr = [{
                key: "fieldName",
                val: fieldName,
            },
            {
                key: "head",
                val: head,
            },
            ];
            let sign = tools.getMD5Sign(signArr);
            let param = {
                fieldName: fieldName,
                head: head,
                sign: sign,
            };
            Api.exportTemplateDownload(param).then((res) => {
                let downloadurl = res.data.result.fileUrl || "";
                //下载(这里其实是直接访问)
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();
            });
        },
        // 真正导出
        templateDataExport(customizedExportId) {
            let className
            let that = this;
            let datas = []
            if (this.pageGroupName == 'WarehousePackagin') {
                datas = that.tableData;
                className = that.className
            } else {
                console.log(that.claimedData)
                datas = that.tableData1
                className = that.className1
            }
            let parm = {
                customizedExportId: customizedExportId, //模板id
                className: className,
                data: datas,
            };
            let signarr = tools.getSignArr(parm);
            let signs_e = tools.getMD5Sign(signarr);
            let array = {
                customizedExportId: customizedExportId,
                className: className,
                data: JSON.stringify(datas),
                sign: signs_e,
            }
            Api.templateDataExport(array).then((res) => {
                //下载(这里其实是直接访问)
                let downloadurl = res.data.result.fileUrl;
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();

            });
        },
        // 上传按钮
        upLoadBtn() {
            this.show_upload = true;
        },
        // 点击导出按钮
        exportBtn() {
            let that = this;
            let fieldName = [];
            let head = [];

            if (this.pageGroupName == 'WarehousePackagin') {
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isShow) {
                        fieldName.push(this.myTableHeard[i].field);
                        head.push(this.myTableHeard[i].name);
                    }
                }
            }
            if (this.pageGroupName == 'WarehousePackagins') {
                for (var i = 0; i < this.myTableHeard1.length; i++) {
                    if (this.myTableHeard1[i].isShow) {
                        fieldName.push(this.myTableHeard1[i].field);
                        head.push(this.myTableHeard1[i].name);
                    }
                }
            }
            this.fieldName = fieldName;
            this.head = head;

            // 1,先获取用户导出模板
            Api.getUserExportTemplate({
                groupName: this.pageGroupName,
            }).then((res) => {
                if (res.data.result && res.data.result[0].customizedExportId) {
                    // 有自定义模板

                    let customizedExportId = res.data.result[0].customizedExportId;
                    that.templateDataExport(customizedExportId);


                } else {
                    if (this.pageGroupName == 'WarehousePackagin') {
                        tools.dynamicExport(
                            this.myTableHeard,
                            this.className,
                            this.tableData,
                            "仓库打包列表"
                        );
                    } else {
                        tools.dynamicExport(
                            this.myTableHeard1,
                            this.className1,
                            this.tableData1,
                            "仓库打包明细列表"
                        );
                    }
                }
            });
        },
        // ===============================================导出部分=结束======

        /**
         * ******************************计算表格高度**开始*************************
         */
        // 重置table高度
        resetHeight() {
            return new Promise((resolve, reject) => {
                this.tableHeigth = 0;
                resolve();
            });
        },
        // 设置table高度
        fetTableHeight() {
            this.resetHeight().then((res) => {
                this.tableHeigth = this.getHeight();
                this.$nextTick(() => {
                    this.$refs.mytable.doLayout();
                });
            });
        },
        // 获取表格高度
        getHeight() {
            let windonHeight = document.body.clientHeight
            let queryHeigth
            let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
            if (this.judge) {
                queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
            } else {
                queryHeigth = 50
            }
            let pagsHeigth = 60; //分页组件
            var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 100;
            return Math.floor(windonHeight - otherHeight) || "auto"; //
        },
        /**
         * ******************************计算表格高度**结束*************************
         */



        //顶部标签页切换
        handleClick() {
            console.log(this.activeName)
            if (this.activeName == 'second') {
                this.pageGroupName = 'WarehousePackagins'
                this.Reset()
                this.SignInRecord()
                this.storagePackDetailList()

            } else {
                this.pageGroupName = 'WarehousePackagin'
                this.getGroupFindFieldSort()
                this.Reset1()
            }
        },
        //签收记录表头获取
        SignInRecord() {
            let that = this;
            console.log(this.pageGroupName)
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        that.myTableHeard1 = JSON.parse(arr[0].sortObject);
                        this.sortName1 = arr[0].sortName;
                    }
                } else {
                    that.myTableHeard1 = that.tabelHeadeTitle1;

                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < that.myTableHeard1.length; i++) {
                    if (that.myTableHeard1[i].isTotal) {
                        totalArr.push(that.myTableHeard1[i].field);
                    }
                }
                that.totalArr1 = totalArr;
            });

            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
        },
        // 获取自定义的排序,没有就用默认的
        getGroupFindFieldSort() {
            let that = this;
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        that.myTableHeard = JSON.parse(arr[0].sortObject);
                        this.sortName = arr[0].sortName;
                    }
                } else {
                    that.myTableHeard = that.tabelHeadeTitle;

                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < that.myTableHeard.length; i++) {
                    if (that.myTableHeard[i].isTotal) {
                        totalArr.push(that.myTableHeard[i].field);
                    }
                }
                that.totalArr = totalArr;
            });

            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
        },
        // 设置模板按钮
        myModel() {
            let that = this;
            that.show_sortTableHeard = true;
            that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //设置模板按钮(签收记录)
        myModel1() {
            let that = this;
            that.show_sortTableHeard1 = true;
            that.tabelHeadeTitle1 = JSON.parse(JSON.stringify(that.myTableHeard1));
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength == that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },

        // 排序==>取消按钮
        cancelSortHeard() {
            this.show_sortTableHeard = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 排序==>确认按钮
        confirmOk(e) {
            this.tabelHeadeTitle = e;
            this.myTableHeard = this.tabelHeadeTitle;
            let sortObject = this.myTableHeard;
            let signs = [{
                key: "sortName",
                val: this.sortName, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            this.show_sortTableHeard = false;
        },
        // 点击某一项
        checktab(idx, e) {
            let that = this;
            that.tabelHeadeTitle[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //全选
        checkAll(e) {
            let that = this;
            this.allCheck = e;
            for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
                this.tabelHeadeTitle[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
        },
        //关闭弹窗
        closeDias(e) {
            this.show_sortTableHeard = e;
        },

        // 合计
        getSummaries(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr.length; i++) {
                    if (column.property == that.totalArr[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            return sums;
        },

        // 排序==>取消按钮(签收记录)
        cancelSortHeard1() {
            this.show_sortTableHeard1 = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 排序==>确认按钮(签收记录)
        confirmOk1(e) {
            this.tabelHeadeTitle1 = e;
            this.myTableHeard1 = this.tabelHeadeTitle1;
            let sortObject = this.myTableHeard1;
            let signs = [{
                key: "sortName",
                val: this.sortName1, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName1, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard1 = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            this.show_sortTableHeard1 = false;
        },
        // 点击某一项
        checktab1(idx, e) {
            let that = this;
            that.tabelHeadeTitle1[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength == that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },
        //全选
        checkAll1(e) {
            let that = this;
            this.allCheck1 = e;
            for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
                this.tabelHeadeTitle1[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
        },
        // 合计
        getSummaries1(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr1.length; i++) {
                    if (column.property == that.totalArr1[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            return sums;
        },
        //关闭弹窗1
        closeDias1(e) {
            this.show_sortTableHeard1 = e;
        },
        //分页
        handleSizeChange(val) {
            this.size = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getData();
        },
        // 上一页
        handlePrevPage() {
            this.pageNum = this.pageNum - 1 || 1;
            this.getData();
        },
        // 下一页
        handleNextPage() {
            this.pageNum = this.pageNum + 1 || 1;
            this.getData();
        },
    }
}